import axios from "axios";
import PATH_ from '../GLOBAL-VAR/globals-urls';

const PATH__ = new PATH_();

class ThemeRequest {
    add(data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "?demande=themes/create/",
                headers: {
                    // 'Content-Type': 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
            })
        )
    }

    addImage(data) {
        return (
            axios({
                method: 'post',
                data: data,
                url: PATH__.api_root + "?demande=images/create/",
                headers: {
                    // 'Content-Type': 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
            })
        )
    }

    GetAll() {
        return (
            axios({
                method: 'get',
                url: PATH__.api_root + "?demande=themes",
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        )
    }

    update(data, id) {
        return (
            axios({
                method: 'put',
                data: data,
                url: PATH__.api_root + "?demande=themes/" + id + "/",
                headers: {
                    // 'Content-Type': 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
            })
        )
    }

}

export default ThemeRequest;