// import React, {useState, useEffect} from 'react';
import React from 'react';
import { Route } from 'react-router';
import { Routes } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
// import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
// import AdbIcon from '@mui/icons-material/Adb';

import HomeView from './homeView.screen';
import ProgramView from './programView.screen';
import RegistrationView from './registration.screen';

import '../styles/home.screen.style.scss';

import logo from '../assets/logos/logo.png';

const pages = [
    {name: 'Accueil', url: '/'},
    {name: "Inscription", url: 'registration'},
    // {name: "Programme", url: 'program'},
    // {name: "Récompenses", url: 'price'},
];

function Home( props ) {
    
    const [anchorElNav, setAnchorElNav] = React.useState(false);
    const handleOpenNavMenu = (event) => {
        
        setAnchorElNav(event.currentTarget);
    };
    
    const handleCloseNavMenu = (event) => {
        // console.log(event);
        setAnchorElNav(false);
    };
      
        

    return (
        <div>
            <nav className="main-nav-one sticky">
                <AppBar position="static" style={{backgroundColor: "#00000000"}}>
                    <Container maxWidth="xl">
                        <Toolbar disableGutters>
                        
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '0rem',
                                color: '#ffffff',
                                textDecoration: 'none',
                                padding: "10px",
                            }}
                        >
                            <img style={{width: "60px"}} src={logo} alt=''/>
                        </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="#ffffff"
                            style={{
                                color: "#ffffff",
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page.name}>
                                    <a className='ab-link' href={page.url}>
                                        <Typography textAlign="center">{page.name}</Typography>
                                    </a>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            justifyContent: "space-between",
                            // flexGrow: 2,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.0rem',
                            color: '#FFFFFFFF',
                            padding: "10px",
                            textDecoration: 'none',
                        }}
                    >
                        <img style={{width: "50px"}} src={logo} alt=''/>
                    </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            <Box sx={{ marginTop: "10px", flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                {pages.map((page) => (
                                    <Button
                                        key={page.name}
                                        // onClick={handleCloseNavMenu}
                                        sx={{ my: 2, color: 'white', display: 'block' }}
                                    >
                                        <a className='at-link' style={{color: "#ffffff"}} href={page.url}>{page.name}</a>
                                        
                                    </Button>
                                ))}
                        </Box>
                        </Box>
                    </Toolbar>
                </Container>
                </AppBar>
            </nav>
            <Routes>
                <Route path='/registration' element={<RegistrationView/>}/>
                <Route path='/program' element={<ProgramView/>}/>
                <Route path='*' element={<HomeView/>}/>
            </Routes>
            {/* <footer className="site-footer">
                <div className="particles-snow" id="footer-snow"></div>
                <div className="site-footer__bottom">
                    <div className="container">
                        <p></p>
                        <a className="allright" href="./"><span>© 2023 </span><span>.Tous droits réservés</span></a>
                        <ul className="list-unstyled site-footer__bottom-menu confidential">
                            <li><a className="mt-2" href="##">Police de confidentialité</a></li>
                            <li>
                               
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>   */}
        </div>
    );
}

export default Home;